import React from "react";
import Slider from "react-slick";
import mapMockUp from "../images/mobile-map.jpg";
import note1MockUp from "../images/mobile-note-1.jpg";
import note2MockUp from "../images/mobile-note-2.jpg";
import timelineMockUp from "../images/mobile-timeline.jpg";
import "devices.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slick-arrows.css";

const demoSlides = [
  {
    name: "Map",
    src: mapMockUp,
  },
  {
    name: "Note1",
    src: note1MockUp,
  },
  {
    name: "Note2",
    src: note2MockUp,
  },
  {
    name: "Timeline",
    src: timelineMockUp,
  },
];

const sliderSettings = {
  infinite: true,
  speed: 250,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  arrow: true,
};

const PhoneMockup = ({ children }) => {
  return (
    <div
      class="device device-iphone-8 device-spacegray mobile-mockup"
      style={{ transform: "scale(0.8)" }}
    >
      <div class="device-frame">{children} </div>
      <div class="device-stripe"></div>
      <div class="device-header"></div>
      <div class="device-sensors"></div>
      <div class="device-btns"></div>
      <div class="device-power"></div>
    </div>
  );
};

const PhoneMockUpSlider = ({ tilt }) => {
  const slider = React.createRef();

  return (
    <div class="mobile-mockup">
      <PhoneMockup>
        <Slider {...sliderSettings} ref={slider}>
          {demoSlides.map((slide) => (
            <img src={slide.src} alt={slide.name} key={slide.name} />
          ))}
        </Slider>
      </PhoneMockup>
    </div>
  );
};

export default PhoneMockUpSlider;
