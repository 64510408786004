import React from "react";
import DatePickerSVG from "../svg/datepicker.inline.svg";
import DiarySVG from "../svg/diary.inline.svg";
import SurferSVG from "../svg/surfer.inline.svg";
import MapSVG from "../svg/map.inline.svg";
import { COLORS } from "../styles/constants";
import SectionHeader from "./section-header";

const Content = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader title="Features" />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div class="feature-text">
        <h3>Personalized Notes</h3>
        <p style={{ color: COLORS.gray }}>
          Create customised content with key data from your sessions: personal
          annotations, meteo, tidal, surf forecast conditions...
        </p>
      </div>
      <DiarySVG width="300" height="300" />
    </content>
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <MapSVG width="300" height="300" />
      <div class="feature-text">
        <h3>Track your Locations and Trips</h3>
        <p style={{ color: COLORS.gray }}>
          Find your favourite surf spots on a map and learn their best
          conditions from the data collected in your notes. Track your surf
          trips and keep in your diary the best surf breaks for future visits.
        </p>
      </div>
    </content>
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <DatePickerSVG width="300" height="300" />
      <div class="feature-text">
        <h3>Time-based Data Visualization</h3>
        <p style={{ color: COLORS.gray }}>
          Includes various tools to allow you to visualize your notes
          chronologically in a Calendar or a Timeline. It also provides
          analytics to know the frequency with which you practice your favourite
          water sports.
        </p>
      </div>
    </content>
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div class="feature-text">
        <h3>Multiple Water Sports</h3>
        <p style={{ color: COLORS.gray }}>
          Surf, Bodyboard, Kitesurf, Windsurf, Wake, SUP... Support of a wide
          variety of sports and activities performed in water environments.
        </p>
      </div>
      <SurferSVG width="300" height="300" />
    </content>
  </div>
);

export default Content;
