import React from "react";
import Slider from "react-slick";
import mapMockUp from "../images/desktop-map.jpg";
import mapNoteMockUp from "../images/desktop-map-note.jpg";
import timelineMockUp from "../images/desktop-timeline.jpg";
import calendarMockUp from "../images/desktop-calendar.jpg";
import analyticsMockUp from "../images/desktop-analytics.jpg";
import "devices.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slick-arrows.css";

const demoSlides = [
  {
    name: "Map",
    src: mapMockUp,
  },
  {
    name: "Map Note",
    src: mapNoteMockUp,
  },
  {
    name: "Timeline",
    src: timelineMockUp,
  },
  {
    name: "Calendar",
    src: calendarMockUp,
  },
  {
    name: "Analytics",
    src: analyticsMockUp,
  },
];

const sliderSettings = {
  infinite: true,
  speed: 250,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  arrow: true,
};

const DesktopMockup = ({ children }) => {
  return (
    <div class="device device-surface-book">
      <div class="device-frame">{children}</div>
      <div class="device-stripe"></div>
      <div class="device-header"></div>
      <div class="device-sensors"></div>
      <div class="device-btns"></div>
      <div class="device-power"></div>
    </div>
  );
};

const DesktopMockUpSlider = ({ tilt }) => {
  const slider = React.createRef();

  return (
    <div class="desktop-mockup">
      <DesktopMockup>
        <Slider {...sliderSettings} ref={slider}>
          {demoSlides.map((slide) => (
            <img
              src={slide.src}
              alt={slide.name}
              key={slide.name}
              width="540"
              height="360"
            />
          ))}
        </Slider>
      </DesktopMockup>
    </div>
  );
};

export default DesktopMockUpSlider;
