import React from "react";

import Button from "../components/button";
import SectionHeader from "./section-header";

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="We want to hear from you!"
      description="SurfNotes is a product in an early stage. We want to evolve, grow, and improve, and we want to involve you in the process"
    />
    <Button>Get Started</Button>
  </div>
);

export default CallToAction;
