import PropTypes from "prop-types";
import React from "react";
import Button from "../components/button";
import headerImage from "../images/header.png";
import DesktopMockUpSlider from "./desktop-mockup";
import Logo from "./logo";
import PhoneMockUpSlider from "./mobile-mockup";

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
      paddingBottom: 0,
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <Logo />
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      Start to create your own water sports diary: find your surf spots, track
      your sessions and learn from them.
    </p>
    <Button>Sign Up - It's Free!</Button>
    <DesktopMockUpSlider />
    <PhoneMockUpSlider />
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
