import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { COLORS, BORDER_RADIUS, GRADIENT } from "../styles/constants";
import "../styles/button.css";

const Button = ({ children }) => (
  <OutboundLink
    href="https://www.go.surfnotes.com/register"
    class="cta-button"
    style={{
      padding: ".5rem 2.5rem",
      color: COLORS.lightWhite,
      fontWeight: 700,
      background: GRADIENT,
      borderRadius: BORDER_RADIUS,
      borderWidth: 0,
      cursor: "pointer",
    }}
  >
    {children}
  </OutboundLink>
);

export default Button;
