import React from "react";
import logo from "../logos/favicon.png";

const Logo = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      userSelect: "none",
      margin: "8px",
    }}
  >
    <img
      src={logo}
      alt="surfnotes-logo"
      style={{
        width: "36px",
        height: "auto",
        margin: "0 4px 0 0",
        paddingBottom: "4px",
      }}
    />
    <h1
      style={{
        margin: "0",
        lineHeight: "1.235",
        letterSpacing: "0.00735em",
        fontSize: "2.0243rem",
        color: "#2196f3",
        display: "inline",
        fontFamily: '"Baloo Bhaijaan", cursive',
        fontWeight: "400",
      }}
    >
      Surf
      <span
        style={{
          margin: "0",
          lineHeight: "1.235",
          letterSpacing: "0.00735em",
          fontSize: "2.0243rem",
          color: "#FFC107",
          fontFamily: '"Baloo Bhaijaan", cursive',
          fontWeight: "400",
        }}
      >
        Notes
      </span>
    </h1>
  </div>
);

export default Logo;
